<div id="login" fxLayout="column" fxLayout.gt-sm="row" fusePerfectScrollbar>
 

    <section  fxFlexOffset="60" class="diagram">
       
    </section>
    <section fxFlex class="titles">
        <div fxLayout="column" >
            <div  fxLayout="row">
                <img class="logo Logo_wed_Class" src="/assets/images/logos/logogro.png" >

            </div>
            <div fxLayout="row" style="text-align: center;" >
                <h1 class="Tribunal_Electrnico_para_la_Ju_Class login-t">
                    <span class="name_title">e</span>.Justicia<br>
                    <span>Administrativa Durango</span>
                </h1>
            </div>
            <div fxLayout="row"  >
              
                <h2 class="login-button">
                    <a class="login-t" matTooltip="INICIAR SESIÓN" (click)="toSignup()">Inicia Sesión</a>
                </h2>
                <h2 class="login-button">
                    <a class="login-t" matTooltip="CREAR CUENTA PARTE ACTORA" [routerLink]="'/pages/auth/register'">Crea una Cuenta</a>
                </h2>
             
            </div>
            <div fxLayout="row"  style="
            margin-left: 53px;
        ">
           
                <h2 class="login-button">
                    <a class="login-t" target="_blank" matTooltip="ESTRADOS DIGITALES" href="{{estradosUrl}}">Estrados digitales</a>
                </h2>
             
            </div>
        </div>
        
    </section>
</div>