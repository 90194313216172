import { FuseNavigation } from '@fuse/types';

const resetFlags = () => {
    console.log('reset flags');
    sessionStorage.setItem('promotions', 'false');
    sessionStorage.setItem('agreements', 'false');
    sessionStorage.setItem('notifications', 'false');
    sessionStorage.setItem('expedients', 'false');
    sessionStorage.setItem('selectedAsesory', undefined);
    sessionStorage.setItem('sentences', 'false');
}

const dashboardMenu: FuseNavigation = {
    id       : 'sample',
    title    : 'Inicio',
    type     : 'item',
    icon     : 'home',
    url      : '/app/inicio',
    function : resetFlags
    /*badge    : {
        title    : '25',
        translate: 'NAV.SAMPLE.BADGE',
        bg       : '#F44336',
        fg       : '#FFFFFF'
    }*/
};


const usersPromoventes: FuseNavigation = {
    id      : 'users-promoventes',
    title   : 'Usuarios Promoventes',
    type    : 'item',
    icon    : 'accessibility_new',
    url  : '/users/list/ciudadano',
    function : resetFlags
}

const amparosMenu: FuseNavigation = {
    id      : 'manager-amparos',
    title   : 'Amparos',
    type    : 'item',
    icon    : 'move_to_inbox',
	url  : '/amparos/all',
	function : resetFlags       
}


const userOfficers: FuseNavigation = {
    id      : 'officers',
    title   : 'Gestión de funcionarios',
    type    : 'item',
    icon    : 'supervisor_account',
    url  : '/users/list/empleado',
    function : resetFlags
}


const authoritiesMenu: FuseNavigation = {
    id   : 'list-authoritie',
    title: 'Autoridades',
    type : 'item',
    icon    : 'security',
    url  : '/users/list/autoridad',
    function : resetFlags
}
const usersMenu: FuseNavigation = {
    id      : 'users',
    title   : '',
    type    : 'group',
    icon    : 'emoji_people',
    children: [
        usersPromoventes,
        userOfficers
    ]
}

// const authoritiesMenu: FuseNavigation = {
//     id      : 'authorities',
//     title   : '',
//     type    : 'group',
//     icon    : 'security',
//     children: [
//         {
//             id      : 'admin-authorities',
//             title   : 'Administración de autoridades',
//             type    : 'collapsable',
//             icon    : 'supervisor_account',
//             children: [
//                 {
//                     id   : 'create-authoritie',
//                     title: 'Entidades',
//                     type : 'item',
//                     url  : '/users/list/entidad'
//                 },
//                 {
//                     id   : 'list-authoritie',
//                     title: 'Autoridades',
//                     type : 'item',
//                     url  : '/users/list/autoridad'
//                 }
//             ]
//         }
//     ]
// }

const expedientMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Notificaciones electrónicas',
    type    : 'collapsable',
    icon    : 'assignment',
    children: [
        {
            id   : 'create-expediente',
            title: 'Solicitud de notificación electrónica en juicio tradicional',
            type : 'item',
            icon : 'notifications_none',
            url  : '/management/expedient-create',
            function : resetFlags
        },
        {
            id   : 'list-expediente',
            title: 'Historial de solicitudes de notificación electrónica en juicio tradicional',
            type : 'item',
            icon : 'room_service',
            url  : '/management/expedients',
            function : resetFlags
        }
    ]
        
}

const expedientAdminMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Solicitud de notificación electrónica en juicio tradicional',
    type    : 'item',
    icon    : 'room_service',
    url  : '/management/expedients',
    function : resetFlags
    /*children: [
        {
            id   : 'list-expediente',
            title: 'Listar expedientes',
            type : 'item',
            url  : '/management/expedients'
        }
    ]*/
}
const expedientSecretaryMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Historial de sentencias',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/all',
	function : resetFlags       
}

const sentencesActuaryMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Gestor de sentencias',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/all',
	function : resetFlags       
}

const expedientMagistradoMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Historial de proyectos de sentencia',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/proyect/all',
	function : resetFlags     
}

const expedientSecretaryStudyMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Proyectos de sentencia',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/proyect/all',
	function : resetFlags    
}

const dashboardEstadisticasMenu: FuseNavigation = {
    id      : 'manager-statistics',
    title   : 'Estadísticas',
    type    : 'item',
    icon    : 'dashboard',
	url  : '/statistics',
	function : resetFlags       
}

const promotionsMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Promociones',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        // {
        //     id   : 'create-demand',
        //     title: 'Registro de demanda / recurso / procedimiento inicial',
        //     type : 'item',
        //     icon : 'edit',
        //     url  : '/promotion/promotion-demand',
        //     function : resetFlags
        // },
        // {
        //     id   : 'create-promotion',
        //     title: 'Registro de promociones',
        //     type : 'item',
        //     icon : 'edit',
        //     url  : '/promotion/promotion-create',
        //     function : resetFlags
        // },
   
        {
            id   : 'create-promotion',
            title: 'Promociones de trámite',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-tramite',           
            function : resetFlags
        },
        {
            id   : 'create-promotion-lesividad',
            title: 'Juicio de Lesividad',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-lesividad',
            function : resetFlags
        },
        {
            id   : 'create-promotion-ra',
            title: 'Juicio de Responsabilidad Administrativa',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-responsabilidad-administrativa',
            function : resetFlags
        },
        {
            id   : 'list-promotion',
            title: 'Historial de promociones',
            type : 'item',
            icon : 'description',
            url  : '/promotion/all',
            function : resetFlags
        }
    ]
}
const promotionsCitizenMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Promociones',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        {
            id   : 'create-demand',
            title: 'Escritos iniciales',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-iniciales',
            function : resetFlags
        },
        {
            id   : 'create-promotion',
            title: 'Promociones de trámite',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-tramite',
            function : resetFlags
        },
     /*    {
            id   : 'create-demand',
            title: 'Juicio de Nulidad',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-nulidad',
            function : resetFlags
        },
        {
            id   : 'create-promotion',
            title: 'Juicio de Responsabilidad Administrativa',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-responsabilidad-administrativa',
            function : resetFlags
        }, */
        {
            id   : 'list-promotion',
            title: 'Historial de promociones',
            type : 'item',
            icon : 'description',
            url  : '/promotion/all',
            function : resetFlags
        }
    ]
}

const promotionsAutorityMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Promociones',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        {
            id   : 'create-promotion',
            // title: 'Registro de demanda / recurso / procedimiento inicial',
            title: 'Registro de promociones',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-create',
            function : resetFlags
        },
        {
            id   : 'list-promotion',
            title: 'Historial de promociones',
            type : 'item',
            icon : 'description',
            url  : '/promotion/all',
            function : resetFlags
        },
        
        
    ]
}

const promotionsOfficersMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Administrar promociones',
    type    : 'item',
    icon  : 'description',
    url   : '/promotion/all',
    function : resetFlags
}

const agreementsOfficersMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Administrar acuerdos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/agreements/all',
    function : resetFlags
}

const agreementsActuaryMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Gestor de acuerdos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/agreements/all',
    function : resetFlags
}

const proyectSentenceMenu: FuseNavigation = {
    id      : 'manager-proyect-sentence',
    title   : 'Proyecto de sentencias',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/sentences/all',
    function : resetFlags
}

const sentencesProyectMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Asuntos recibidos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/sentences/all',
    function : resetFlags
}

const agreementsProyectMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Asuntos recibidos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/agreements/all',
    function : resetFlags
}

const notificationMenu: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Buzón de notificaciones',
    type    : 'item',
    icon    : 'mail',
    url     : '/notifications/mail',
    function : resetFlags
}

const notificationActuaryMenu: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Historial de notificaciones',
    type    : 'item',
    icon    : 'mail',
    url     : '/notifications/mail',
    function : resetFlags
}

const actionsActuaryMenu: FuseNavigation[] = [
    //@ALAN SE OCULTA LOS BOTONES YA QUE NO JALA EL MODULO DE AUTOCOMPLETAR
    {
        id      : 'electronica',
        title   : 'Notificación Electrónica',
        type    : 'item',
        function : resetFlags,
        icon    : 'mail',
    },
    {
        id      : 'fisica',
        title   : 'Notificación Física',
        type    : 'item',
        function : resetFlags,
        icon    : 'mail',
    }
]

const asesorAction: FuseNavigation[] = [
    {
        id      : 'adviser-list',
        title   : 'Buzón de asesoría ciudadana',
        type    : 'item',
        function : resetFlags,
        url     : '/administrator/advisers',
        icon    : 'question_answer',
    }
]

const citizenAction: FuseNavigation[] = [
    {
        id      : 'adviser-list',
        title   : 'Asesoría ciudadana',
        type    : 'item',
        function : resetFlags,
        url     : '/administrator/advisers',
        icon    : 'question_answer',
    }
]

const expedientJudgment: FuseNavigation = 
{
    id: 'expedient-judgment',
    title: 'Asuntos resueltos sin previo juicio',
    type: 'item',
    url: '/topics/topic-create',
    icon: 'question_answer',
}

const expedientTopic: FuseNavigation = {
    id: 'expedient-topic',
    title: 'Asuntos resueltos sin previo juicio',
    type: 'item',
    url: '/topics/all',
    icon: 'question_answer',
}

export const adminMenu: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            dashboardMenu,
            usersPromoventes,
            userOfficers,
            authoritiesMenu,
        ]
    }
];

export const actuaryMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        agreementsActuaryMenu,
        sentencesActuaryMenu,
        ...actionsActuaryMenu,
        notificationActuaryMenu,
        
    ]
}];

export const actuaryMenuDashboard: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        amparosMenu,
        agreementsActuaryMenu,
        sentencesActuaryMenu,
        ...actionsActuaryMenu,
        notificationActuaryMenu,
    ]
}];

export const secretaryMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsOfficersMenu,
        amparosMenu,
        agreementsOfficersMenu,
        expedientSecretaryMenu,
        expedientAdminMenu,
        //expedientJudgment,
        //expedientTopic,
    ]
}];

export const streamMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsMenu,
        notificationMenu,
        expedientMenu,
        // ...citizenAction
    ]
}];

export const citizenMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsCitizenMenu,
        notificationMenu,
        expedientMenu,
        // ...citizenAction
    ]
}];

export const peritoMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsCitizenMenu,
        notificationMenu,
        //expedientMenu,
        // ...citizenAction
    ]
}];

export const asesorMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        ...asesorAction,
        promotionsCitizenMenu,
        notificationMenu,
        expedientMenu,
    ]
}];

export const authorityMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsMenu,
        notificationMenu,
        expedientMenu,
        // {
        //     id   : 'create-demand',
        //     title: 'Registro juicio de lesividad',
        //     type : 'item',
        //     icon : 'edit',
        //     url  : '/promotion/promotion-demand',
        //     function : resetFlags
        // }
        
        
    ]
}];

export const magistradoMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        amparosMenu,
        agreementsActuaryMenu,
        proyectSentenceMenu,
		expedientMagistradoMenu,
		/* dashboardEstadisticasMenu */
    ]
}];

export const proyectistaMenu: FuseNavigation[] = [{
    id: 'applications',
    title: '',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
        dashboardMenu,
        sentencesProyectMenu,
        expedientSecretaryStudyMenu
    ]
}];