export class AppConfig {
    // ######### LOCAL ##########
//    url: string = 'http://127.0.0.1:8002/api/';
//    urlEstrados: string = 'https://boletin.tjadurango.gob.mx/inicio';
//    criptographicEvidenceURL: string = 'http://127.0.0.1:8002/firmarArchivo';
//    CJFCatalogosURL='http://127.0.0.1:8002/cjf';

    // ######### DEPLOYS  ##########
   
    url: string = 'https://api.tjadurango.gob.mx/api/';
    urlEstrados: string = 'https://boletin.tjadurango.gob.mx/inicio';
    criptographicEvidenceURL: string = 'https://api.tjadurango.gob.mx/firmarArchivo';
    CJFCatalogosURL='https://api.tjadurango.gob.mx/cjf'; 

}                